<template>
  <div class="h5-container" :class="{ 'no-tabbar': hideTabbar }">
    <keep-alive include="Home">
      <router-view class="main-content" />
    </keep-alive>
    <van-tabbar
      v-if="!hideTabbar"
      v-model="active"
      active-color="#ca0a15"
      inactive-color="#B5B5B5"
      placeholder
      route
      safe-area-inset-bottom
    >
      <van-tabbar-item replace to="/h5/home" icon="home-o"
        >首页</van-tabbar-item
      >
      <van-tabbar-item replace to="/h5/search" icon="search"
        >查谱</van-tabbar-item
      >
      <van-tabbar-item replace to="/h5/events" icon="location-o"
        >寻根</van-tabbar-item
      >
      <van-tabbar-item replace to="/h5/lecture_home" icon="wap-home-o"
        >学堂</van-tabbar-item
      >
      <van-tabbar-item replace to="/h5/user/center" icon="user-circle-o"
        >个人中心</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Tabbar, TabbarItem } from "vant";
import { createOAuthUrl } from "@/utils/redir";
export default {
  name: "H5Layout",
  components: {
    "van-tabbar": Tabbar,
    "van-tabbar-item": TabbarItem
  },
  data() {
    return {
      active: 0
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    hideTabbar() {
      console.log(this.$route);
      return this.$route.meta.hideTabbar;
    }
  },
  created() {
    this.fetchUserInfo();
    // this.fetchVipIndex();
    // this.checkIsWeixin();
    console.log("created");
  },
  methods: {
    ...mapActions(["fetchUserInfo", "fetchVipIndex", "fetchOpenId"]),
    checkIsWeixin() {
      const ua = navigator.userAgent;
      const {
        query: { code }
      } = this.$route;
      console.log("this.$route.query", this.$route.query);
      if (/\bMicroMessenger\/([\d.]+)/.test(ua)) {
        const openid = localStorage.getItem("shuyuan_openid");
        if (openid) return;
        // 是微信内
        if (!code) {
          const redirUrl = createOAuthUrl(window.location.href, window.appid);
          console.log(redirUrl);

          // window.location.replace(redirUrl);

          window.location.href = redirUrl;
        } else {
          console.log("code", code);
          this.fetchOpenId({ code })
            .then(() => {
              // this.$router.replace({
              //   path: this.$route.path,
              //   query: { ...this.$route.query, code: "" }
              // });
            })
            .catch(() => {
              console.log(this.$route.fullPath);
              window.location.href =
                window.location.origin + window.location.pathname;
            });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.h5-container {
  background-color: #fff;
  min-height: 100vh;
}
</style>
