<template>
  <div class="container">
    <img src="@/assets/images/header/header_bg.png" alt="" class="header-bg" />
    <header-nav />
    <div class="container-inner">
      <router-view />
    </div>
    <div class="footer">
      <div class="top">
        <div class="links">
          <div class="links-left">
            <div class="link">
              <div>产品服务</div>
              <div>编修族谱</div>
              <div>谱牒数字化</div>
              <div>内容生产</div>
            </div>
            <div class="link">
              <div>关于我们</div>
              <div>公司简介</div>
              <div>新闻资讯</div>
              <div>渠道合作</div>
            </div>
            <div class="link">
              <div>用户服务</div>
              <div>售后服务</div>
            </div>
          </div>
          <div class="links-right">
            <div class="telephone">
              <img src="@/assets/images/index/telephone.png" alt="" />
              <span>13617482511</span>
            </div>
            <div class="address">
              地址：长沙市芙蓉区迎宾路38号201-7
            </div>
          </div>
        </div>
        <div class="qrcodes">
          <img src="@/assets/images/index/qr1.jpg" alt="" class="qr-code1" />
          <img src="@/assets/images/index/qr2.jpg" alt="" class="qr-code2" />
        </div>
      </div>
      <div class="bottom">
        版权所有 © Copyright 2019 名门家谱保留一切权利
        湘ICP备2022012215号
      </div>
    </div>
    <login-dialog />
    <div class="side-bar">
      <a
        class="side-item"
        v-for="item in side"
        :key="item.label"
        :href="item.link || 'javascript: void(0)'"
        target="_blank"
      >
        <el-tooltip placement="left" effect="light">
          <div slot="content">
            <img
              class="side-image"
              v-if="item.image"
              :src="item.image"
              alt=""
            />
            <div class="side-text" v-if="item.text">{{ item.text }}</div>
          </div>
          <img class="icon" :src="item.icon" alt="" />
        </el-tooltip>
        <div class="label">{{ item.label }}</div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Tooltip } from "element-ui";
import HeaderNav from "@/components/web/Header";
import LoginDialog from "@/components/web/LoginDialog";

export default {
  name: "WebLayout",
  components: {
    HeaderNav,
    LoginDialog,
    [Tooltip.name]: Tooltip
  },
  data() {
    return {
      side: [
        // {
        //   icon: require("@/assets/images/qq.png"),
        //   label: "QQ咨询",
        //   link: "http://wpa.qq.com/msgrd?v=3&uin=2433773914&site=qq&menu=yes",
        //   text: "QQ咨询：2433773914"
        // },
        {
          icon: require("@/assets/images/tele.png"),
          label: "联系电话",
          link: "",
          text: "联系电话：13617482511"
        },
        {
          icon: require("@/assets/images/weapp.png"),
          label: "小程序",
          link: "",
          image: require("@/assets/images/index/qr2.jpg")
        },
        {
          icon: require("@/assets/images/wechat.png"),
          label: "微信客服",
          link: "",
          image: require("@/assets/images/index/qr3.jpg")
          // text: "微信客服：g18163930632",
        },
        {
          icon: require("@/assets/images/veri.png"),
          label: "公众号",
          link: "",
          image: require("@/assets/images/index/qr1.jpg")
        }
      ]
    };
  },
  created() {
    this.fetchUserInfo();
    // this.fetchVipIndex();
  },
  methods: {
    ...mapActions(["fetchUserInfo", "fetchVipIndex"])
  }
};
</script>

<style>
.side-image {
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.side-text {
  height: 100px;
  line-height: 100px;
  font-size: 16px;
  font-weight: bold;
}

.el-tooltip__popper.is-light {
  border: none;
}
</style>

<style lang="scss" scoped>
.container {
  min-width: 1200px;
  width: 100%;
  background-color: #f5f5f5;

  .header-bg {
    position: absolute;
    width: 100%;
    // height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  .container-inner {
    position: relative;
    overflow: auto;
    height: 100%;
    // background-color: #f5f5f5;
    // background: linear-gradient(0deg, #fbe2c4 0%, #faf3ea 100%);
  }

  .side-bar {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 90px;
    background: #ffffff;
    box-shadow: -6px 0px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 20px 0px 0px 20px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 10px 0;

    .side-item {
      padding: 14px 0;
      text-align: center;
      border-bottom: 1px solid #f5f5f5;
      cursor: pointer;

      &:last-of-type {
        border-bottom: none;
      }

      .text {
        height: 30px;
        width: 30px;
        display: block;
      }

      .icon {
        width: 30px;
        object-fit: contain;
      }

      .label {
        margin-top: 10px;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}

.footer {
  margin-top: 30px;
  height: 240px;
  width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  background: #292929;

  .top {
    height: 179px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .links {
      display: flex;
      .links-left {
        width: 360px;
        display: flex;
        justify-content: space-around;
        border-right: 1px solid #fff;
        .link {
          color: #ffffff;
          font-size: 14px;
          line-height: 30px;

          > div:first-of-type {
            font-size: 16px;
          }
        }
      }

      .links-right {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
        padding-left: 28px;
        .telephone {
          font-size: 24px;
          color: #fff;
          display: flex;
          align-items: center;

          img {
            height: 45px;
            width: 45px;
            margin-right: 13px;
          }
        }

        .address {
          font-size: 16px;
          color: #fff;
          margin-top: 14px;
        }
      }
    }

    .qrcodes {
      margin-right: 10px;

      img {
        margin-right: 10px;
        height: 122px;
        width: 122px;
      }
    }
  }

  .bottom {
    height: 60px;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 60px;
  }

  a {
    color: #CA0A15;
    font-size: 14px;
  }
}
</style>
